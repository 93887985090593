import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import '../App.css';
import AddCustom from '../assets/addCustom.png'
import addLink from '../assets/addLink.png'


const App = () => {
    console.log("instruction")
    return (
        <div className="main-container">
            <InstructionsSection />
        </div>
    );
};

const InstructionsSection = () => {
    const urlString = "https://connect.linked2checkout.com/ghlAauthentication/:{{location.id}}";
    return (
        <div className="instructions-section">
            <h1>Final step to install linked2checkout into your CRM</h1>
            <p>How to add a custom menu link in your sub location for linked2checkout:</p>
            <ol>
                <li>1. Log into your AGENCY and click on Settings.</li>
                <li>
                    2. In the settings menu click on "Custom Menu Links" then click on "Create
                    New"
                </li>
                    <img src={AddCustom} alt="custom menu link" className="custom_menu_create_img" />
                <li>
                    3. Follow the prompts to add the custom menu link to our desired sub
                    location. When prompted you will need to add this URL exactly as
                    shown. Just copy and paste:
                    <pre className="url-example">
                        Menu URL: <strong dangerouslySetInnerHTML={{ __html: urlString }} />
                    </pre>
                    <p>
                        <p>
                            ****Please note you must copy and paste this as is, do not edit
                            this URL text in anyway.****
                        </p>
                    </p>
                    <img src={addLink} alt="custom menu link" className="custom_menu_link_img" />
                </li>
            </ol>
            <p>
                Once saved navigate to the sub account with the new linked2checkout Menu,
                scroll down and the new menu button will be at the bottom of the menu
                list.
            </p>
            <p>
               
            </p>
        </div>
    );
};




export default App;
