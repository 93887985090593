import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../service/auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LogoGhl from "../service/logoGHL";
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import '../App.css';
import TextareaAutosize from 'react-textarea-autosize';

const Login = () => {
    const navigate = useNavigate();
    const [apiKey, setApiKey] = useState("");
    const [loading, setLoading] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [LocationId, setLocationId] = useState("");
    const [SiteId, setSiteId] = useState("");
    const [company_id, setCompnayId] = useState("");
    const [allLocations, setAllLocations] = useState([]);
    const [companyAccessToken, setCompanyAccessToken] = useState("");
    const [Connecting, setConnecting] = useState(false);
    const [visible, setVisible] = useState(false);
    const [ServerError, setServerError] = useState("");
    const [inputErrors, setInputErrors] = useState({
        LocationId: false,
        apiKey: false,
        SiteId: false,
        accessToken: false,
    });

    useEffect(() => {
        const url = window.location.href;
        const parsedUrl = new URL(url);
        const queryParams = new URLSearchParams(parsedUrl.search);
        let locations = queryParams.get('locations');
        let company_access_token = queryParams.get('access_');
        let company_id = queryParams.get('c_id');
        setCompnayId(company_id);
        setCompanyAccessToken(company_access_token);
        locations = locations.replace("[", "").replace("]", "");
        console.log('locations', locations);
        locations = locations.includes(",") ? locations.split(",") : [locations];
        console.log('locationslocations', locations);
        setAllLocations(locations);
        getLocationID();
    }, []);

    useEffect(() => {
        resize();
    }, [apiKey, accessToken]);

    const resize = () => {
        const textarea = document.getElementById('autosize');
        const textarea2 = document.getElementById('accessToken');
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight - 2}px`;
        }
        if (textarea2) {
            textarea2.style.height = 'auto';
            textarea2.style.height = `${textarea2.scrollHeight - 2}px`;
        }
    };

    function getLocationID() {
        try {
            // Location ID
            var l_id = localStorage.getItem("location_id");
            var w_href = window.location.href ? window.location.href.split(":") : "";
            if (l_id) {
                if (l_id == w_href[2]) {
                    setLocationId(l_id);
                    localStorage.setItem("location_id", l_id);
                } else {
                    if (w_href[2] == "id") {
                        setLocationId(l_id);
                        localStorage.setItem("location_id", l_id);
                    } else {
                        setLocationId(w_href[2]);
                        localStorage.setItem("location_id", w_href[2]);
                    }
                }
            } else {
                localStorage.setItem("location_id", w_href[2]);
                setLocationId(w_href[2]);
            }
        } catch (error) { }
    }

    const handleInputChange = (e, inputName) => {
        const value = e.target.value;
        setServerError("");
        const newInputErrors = { ...inputErrors, [inputName]: !value };
        setInputErrors(newInputErrors);
        switch (inputName) {
            case "LocationId":
                setLocationId(value);
                break;
            case "apiKey":
                setApiKey(value);
                break;
            case "SiteId":
                setSiteId(value);
                break;
            case "accessToken":
                setAccessToken(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!SiteId || !accessToken) {
            setInputErrors({
                SiteId: !SiteId,
                accessToken: !accessToken,
            });
            return;
        }
        try {
            setConnecting(true);
            var postData = {
                locationAPIKeyToken: apiKey,
                location_id: LocationId,
                site_id: SiteId,
                access_token: accessToken,
                all_locations: allLocations,
                c_a_token: companyAccessToken,
                c_id: company_id
            };
            console.log('postData', postData);
            const responseData = await axios.post(`${process.env.REACT_APP_URL}/api/auth-connection`, postData);
            if (responseData && responseData.data && responseData.data.status) {
                toast.success(responseData.data.message);
                setTimeout(() => {
                    navigate("/connectApp");
                }, 1000);
            } else {
                document.body.innerHTML = "internal server error";
            }
            // auth.connectionUser(responseData);
            // localStorage.setItem("isConnection", true);
            // let data = { accessToken, SiteId };
            // const dataString = JSON.stringify(data);
            // localStorage.setItem("accessToken", dataString);
            // navigate("/dashboard");
        } catch (error) {
            console.log('error', error);
            setConnecting(false);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="conntection-section">
                                <div className="brandLogo">
                                    <LogoGhl />
                                    <ToastContainer />
                                </div>
                                <div className="auth_Configuration">
                                    <h2>Configuration</h2>
                                    {/* <label className="form-label">
                                        <span>
                                            Location Id
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-right">GHL Location Id</Tooltip>}
                                            >
                                                <i className="bi bi-info-circle-fill"></i>
                                            </OverlayTrigger>
                                        </span>
                                        <a target="_blank" rel="noreferrer" href="https://app.gohighlevel.com/sub-accounts/">How to get</a>
                                    </label>
                                    <input
                                        type="text"
                                        id="LocationId"
                                        className={inputErrors.LocationId ? "errorColor form-control" : "form-control location-id-input"}
                                        value={LocationId}
                                        onChange={(e) => handleInputChange(e, "LocationId")}
                                        placeholder="Enter your Location Id"
                                        disabled={LocationId ? true : false}
                                    /> 
                                    <label className="form-label">
                                        <span>
                                            Location API Key Token
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-right">GHL Location API Key</Tooltip>}
                                            >
                                                <i className="bi bi-info-circle-fill"></i>
                                            </OverlayTrigger>
                                        </span>
                                        <a target="_blank" rel="noreferrer" href="https://app.gohighlevel.com/settings/api_key">How to get</a>

                                    </label>
                                    <TextareaAutosize
                                        type="text"
                                        id="autosize"
                                        className={inputErrors.apiKey ? "autosize textarea-no-scrollbar errorColor " : "autosize textarea-no-scrollbar "}
                                        rows="1"
                                        value={apiKey}
                                        onChange={(e) => handleInputChange(e, "apiKey")}
                                        placeholder="Enter your API key"
                                    />
                                     <p className="info">You can find it on Setting -&gt; Business Profile -&gt; General Information</p>   */}

                                    <label className="form-label">
                                        <span>
                                            Site Id
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-right">360Five Checkout Site Id</Tooltip>}
                                            >
                                                <i className="bi bi-info-circle-fill"></i>
                                            </OverlayTrigger>
                                        </span>
                                        <a target="_blank" rel="noreferrer" href="https://linked2checkout.com/docs/3.0/introduction">How to get</a>
                                    </label>
                                    <TextareaAutosize
                                        type="text"
                                        id="SiteId"
                                        className={inputErrors.SiteId ? "autosize textarea-no-scrollbar errorColor" : "autosize textarea-no-scrollbar"}
                                        value={SiteId}
                                        onChange={(e) => handleInputChange(e, "SiteId")}
                                        placeholder="Enter your Site Id"
                                    />

                                    <label className="form-label">
                                        <span>
                                            Access Token
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-right">360Five Checkout Access Token</Tooltip>}
                                            ><i className="bi bi-info-circle-fill"></i>
                                            </OverlayTrigger>
                                        </span>
                                        <a target="_blank" rel="noreferrer" className="aTagLink" href="https://linked2checkout.com/docs/3.0/introduction">How to get</a>
                                    </label>
                                    <TextareaAutosize
                                        type="text"
                                        id="accessToken"
                                        className={inputErrors.accessToken ? "autosize textarea-no-scrollbar errorColor " : "autosize textarea-no-scrollbar"}
                                        rows="1"
                                        value={accessToken}
                                        onChange={(e) => handleInputChange(e, "accessToken")}
                                        placeholder="Enter your access token"
                                    />
                                    <div className="create-connection">
                                        <button
                                            className="filter mt-3 m-auto"
                                            onClick={handleSubmit}
                                        >
                                            {loading ? "Connecting..." : "Save"}{" "}
                                        </button>
                                        {/* <button type="button"
                                            className="mt-3 m-auto connectionBackBtn" onClick={(e) => setVisible(!visible)}>
                                            Back
                                        </button> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Login;