import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import auth from "../service/auth";
import LogoGhl from "../service/logoGHL";
import { ToastContainer, toast } from 'react-toastify'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import '../App.css';
import TextareaAutosize from 'react-textarea-autosize';

const GhlAuthentication = () => {
    const [ServerError, setServerError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [apiKey, setApiKey] = useState("");
    const [locationId, setLocationId] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [siteId, setSiteId] = useState("");
    const [inputErrors, setInputErrors] = useState({
        LocationId: false,
        apiKey: false,
        SiteId: false,
        accessToken: false,
    });
    const parseUrlParams = () => {

        const url = window.location.href;
        let id = url.match(/\/ghlAauthentication\/(.+)/)[1];
        if (id.startsWith(':')) {
            id = id.substring(1);
        }
        localStorage.setItem("location_id", id);
        setLocationId(id);
        // setLocationId("XURnFTU0CPCXxOKPrfT8");


        // if (id === "XURnFTU0CPCXxOKPrfT8") {
        //     setlocationAPIKeyToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6IlhVUm5GVFUwQ1BDWHhPS1ByZlQ4IiwidmVyc2lvbiI6MSwiaWF0IjoxNzIxNzQyNDA1MTA5LCJzdWIiOiJEMk93ZmdXbDZ1SFdkMXBYNnB2diJ9.YY3PAZz2Ru_u_lmwTE116SSkSzxf92rqQAe-1UanG3w")
        // } else if (id === "qiyIVQawak1YZGjvAmb6") {
        //     setlocationAPIKeyToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6InFpeUlWUWF3YWsxWVpHanZBbWI2IiwidmVyc2lvbiI6MSwiaWF0IjoxNzIxNzQyMzU0NzQ4LCJzdWIiOiJEMk93ZmdXbDZ1SFdkMXBYNnB2diJ9.uTxKUyWNWhCWluuX9Pgo9KttDejsfrJGhrFl2-IKEh4")
        // }
    };


    useEffect(() => {
        parseUrlParams();
    }, []);
    const handleInputChange = (e, inputName) => {
        const value = e.target.value;
        setServerError("");
        const newInputErrors = { ...inputErrors, [inputName]: !value };
        setInputErrors(newInputErrors);
        switch (inputName) {
            case "LocationId":
                setLocationId(value);
                break;
            case "apiKey":
                setApiKey(value);
                break;
            case "SiteId":
                setSiteId(value);
                break;
            case "accessToken":
                setAccessToken(value);
                break;
            default:
                break;
        }
    };
    const handleConnection = async () => {
        toast.dismiss();
        if (!locationId) {
            console.error("Missing location ID.");
            return;
        }

        if (!locationId || !apiKey || !siteId || !accessToken) {
            setInputErrors({
                LocationId: !locationId,
                apiKey: !apiKey,
                SiteId: !siteId,
                accessToken: !accessToken,
            });
            return;
        }
        setLoading(true);
        try {
            setLoading(false);
            const postData = {
                location_id: locationId,
                locationAPIKeyToken: apiKey,
                site_id: siteId,
                access_token: accessToken,
            };
            const connectionResponse = await axios.post(`${process.env.REACT_APP_URL}/api`, postData);

            connectionResponse.data.data.apiKey = apiKey;

            auth.connectionUser(connectionResponse);
            localStorage.setItem("isConnection", true);
            let data = { accessToken: accessToken, SiteId: siteId };
            const dataString = JSON.stringify(data);
            localStorage.setItem("accessToken", dataString);
            navigate("/dashboard");
        } catch (error) {
            // console.log("error", error);
            setLoading(false);
            if (error.response) {
                console.log("Error response:", error.response);
                const message = error.response?.data?.error.status === 401
                    ? "Unauthorized, Invalid Location API Key Token"
                    : error.response?.data?.error.status === 422
                        ? "Unauthorized, Invalid Location API Key Token" : error.response?.data?.error.status === 498 ||  error.response?.data?.error.status === 401  ? 'Invalid Site Id or Access Token'
                            : error.response?.data?.error.message || "An error occurred.";
                toast.error(message);
            } else if (error.request) {
                console.log("No response:", error.request);
                const message = error.response?.data?.error || "An error occurred.";
                toast.error(message);
            } else {
                console.log("Error message:", error.message);
                const message = error.response?.data?.error || "An error occurred.";
                toast.error(message);
            }
        }
    };


    return (
        <div className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="conntection-section">
                            <div className="brandLogo">
                                <LogoGhl />
                                <ToastContainer />
                            </div>
                            {/* <div className="connection_btn">
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="filter mt-3 m-auto"
                                        onClick={handleInputChange}
                                    >
                                        {loading ? "Connecting..." : "Make Connection"}{" "}
                                    </button>

                                </div>
                            </div> */}
                            <div className="auth_Configuration">
                                <h2>Configuration</h2>

                                <label className="form-label">
                                    <span>
                                        Location API Key Token
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-right">GHL Location API Key</Tooltip>}
                                        >
                                            <i className="bi bi-info-circle-fill"></i>
                                        </OverlayTrigger>
                                    </span>
                                    <a target="_blank" rel="noreferrer" href="https://app.gohighlevel.com/settings/api_key">How to get</a>

                                </label>
                                <TextareaAutosize
                                    type="text"
                                    id="autosize"
                                    className={inputErrors.apiKey ? "autosize textarea-no-scrollbar errorColor " : "autosize textarea-no-scrollbar "}
                                    rows="1"
                                    value={apiKey}
                                    onChange={(e) => handleInputChange(e, "apiKey")}
                                    placeholder="Enter your API key"
                                />
                                <p className="info">You can find it on Setting -&gt; Business Profile -&gt; General Information</p>

                                <label className="form-label">
                                    <span>
                                        Site Id
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-right">360Five Checkout Site Id</Tooltip>}
                                        >
                                            <i className="bi bi-info-circle-fill"></i>
                                        </OverlayTrigger>
                                    </span>
                                    <a target="_blank" rel="noreferrer" href="https://linked2checkout.com/docs/3.0/introduction">How to get</a>
                                </label>
                                <TextareaAutosize
                                    type="text"
                                    id="SiteId"
                                    className={inputErrors.SiteId ? "autosize textarea-no-scrollbar errorColor" : "autosize textarea-no-scrollbar"}
                                    value={siteId}
                                    onChange={(e) => handleInputChange(e, "SiteId")}
                                    placeholder="Enter your Site Id"
                                />

                                <label className="form-label">
                                    <span>
                                        Access Token
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-right">360Five Checkout Access Token</Tooltip>}
                                        ><i className="bi bi-info-circle-fill"></i>
                                        </OverlayTrigger>
                                    </span>
                                    <a target="_blank" rel="noreferrer" className="aTagLink" href="https://linked2checkout.com/docs/3.0/introduction">How to get</a>
                                </label>
                                <TextareaAutosize
                                    type="text"
                                    id="accessToken"
                                    className={inputErrors.accessToken ? "autosize textarea-no-scrollbar errorColor " : "autosize textarea-no-scrollbar"}
                                    rows="1"
                                    value={accessToken}
                                    onChange={(e) => handleInputChange(e, "accessToken")}
                                    placeholder="Enter your access token"
                                />
                                <div className="create-connection">
                                    <button
                                        className="filter mt-3 m-auto"
                                        onClick={handleConnection}
                                    >
                                        {loading ? "Connecting..." : "Make Connection"}{" "}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default GhlAuthentication;

